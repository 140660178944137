import classnames from 'classnames';
import TopMenu from 'components/TopMenu';
import React, { ReactNode } from 'react';
import { ClassnamesArgument } from 'types/base';

import CSS from './Page.module.css';

type Props = {
    children: ReactNode;
    className?: ClassnamesArgument;
    showBack?: boolean;
};

export default function Page({ children, className }: Props) {
    return <div className={classnames(CSS.Page, className)}>{children}</div>;
}

export function PageTopMenu({ children, className, showBack = false }: Props) {
    return (
        <>
            <TopMenu showBack={showBack} />
            <Page children={children} className={className} />
        </>
    );
}