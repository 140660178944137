import { GRAPHQL_ENDPOINT } from 'consts';
import { useEffect } from 'react';
import { createGlobalState, useObservable } from 'react-use';
import { RxDocumentData } from 'rxdb';
import { RxGraphQLReplicationState } from 'rxdb/dist/types/plugins/replication-graphql';
import { of } from 'rxjs';
import { Sdk } from 'types/api';

import {
    PosterDocType,
    pullQueryBuilder,
    pushQueryBuilder,
} from './models/posters';
import { useCurrentCampaign } from './models/useCampaignData';
import { CampaignerDatabase, useDb } from './useDatabase';
import { useSdk } from './useSdk';
import { useFreshSessionToken } from './useSession';

type SyncState = RxGraphQLReplicationState<PosterDocType>;
export const SyncState = createGlobalState<SyncState>();

export const useSyncState = function () {
    const [value] = SyncState();
    return value;
};

export async function createSync(
    sdk: Sdk,
    db: CampaignerDatabase,
    campaignId: string,
    sessionToken: string,
    userId: string,
    sessionId: string,
    onlySyncYourOwn = true,
) {
    console.log('starting sync');
    const sync = db.posters.syncGraphQL({
        url: GRAPHQL_ENDPOINT,
        waitForLeadership: true,
        headers: {
            Authorization: `Bearer ${sessionToken}`,
        },
        pull: {
            queryBuilder: pullQueryBuilder(
                campaignId,
                onlySyncYourOwn ? userId : undefined,
            ),
            batchSize: 50,
            // dataPath: 'posterList',

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            modifier: (doc: any) => {
                console.log('pull incoming', doc);
                const alteredDoc: PosterDocType = {
                    ...doc,
                    location: doc.location.geojson,
                    entryDeleted: false,
                    docType: 'poster',
                };
                if (onlySyncYourOwn && alteredDoc.createdBy !== userId) {
                    console.log('skipping pull of doc', doc);
                    return null;
                }
                console.log('pull modifier', alteredDoc);
                return alteredDoc;
            },
        },
        push: {
            queryBuilder: pushQueryBuilder(sdk, userId, sessionId),
            batchSize: 1,
            modifier: (doc: RxDocumentData<PosterDocType>) => {
                if (doc._deleted && !doc.removed) {
                    console.log('skipping push of doc', doc);
                    return null;
                }
                // workaround for https://github.com/graphile/postgis/issues/31
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (doc.location as any).crs = {
                    type: 'name',
                    properties: {
                        name: 'urn:ogc:def:crs:EPSG::4326',
                    },
                };
                return doc;
            },
        },
        deletedFlag: 'entryDeleted', // the flag which indicates if a pulled document is deleted
        live: true, // if this is true, rxdb will watch for ongoing changes and sync them
        retryTime: 60 * 1000,
        liveInterval: 60 * 1000,
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).syncSingleton = sync;
    return sync;
}

export function useCreateSync() {
    const db = useDb();
    const campaignId = useCurrentCampaign()?.campaign.id;
    const [syncState, setSyncStateValue] = SyncState();
    const session = useFreshSessionToken();
    const sdk = useSdk();
    return useEffect(() => {
        (async () => {
            if (session.value && !syncState && db && campaignId) {
                const { sub, session_id } = session.value.parsedShortLived;
                const sync = await createSync(
                    sdk,
                    db,
                    campaignId,
                    session.value.token,
                    sub,
                    session_id,
                );
                setSyncStateValue(sync);
                console.log(
                    'created sync state, waiting for initial replication',
                );
                await sync.awaitInitialReplication();
                console.log('initial replication done');
                sync.error$.subscribe((error: unknown) => {
                    console.error('sync error', error);
                });
                sync.received$.subscribe((doc: PosterDocType) => {
                    console.log('receiving', doc);
                });
                sync.send$.subscribe((doc: PosterDocType) => {
                    console.log('sending', doc);
                });
                console.log('created sync state');
            }
        })();
    }, [campaignId, db, session.value, setSyncStateValue, syncState, sdk]);
}

export function useSyncIsActive() {
    const sync = useSyncState();
    useCreateSync();
    const initialDone = useObservable(
        sync?.initialReplicationComplete$ || of(false),
    );
    const active = useObservable(sync?.active$ || of(false));

    const run = () => {
        try {
            if (sync) {
                sync?.run?.(true);
            }
        } finally {
        }
    };
    const finalActive = !initialDone ? true : active;
    return { active: finalActive, run };
}

export const useSyncOnline = function () {
    const sync = useSyncState();
    return sync ? !sync.isStopped() : false;
};
