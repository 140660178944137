import './index.css';
import './i18n';

import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { LoadingPage } from 'pages/LoadingPage';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

const setDocHeight = () =>
    document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight / 100}px`,
    );

window.addEventListener('resize', setDocHeight);
window.addEventListener('orientationchange', setDocHeight);
setDocHeight();

const LazyRoot = React.lazy(() => import('pages/Root'));
const element = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(element!);
root.render(
    <React.StrictMode>
        <ErrorBoundary name="Main">
            <Suspense fallback={<LoadingPage />}>
                <LazyRoot />
            </Suspense>
        </ErrorBoundary>
    </React.StrictMode>,
);
