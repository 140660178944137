import classNames from 'classnames';
import { useAuthState } from 'hooks/useAuth';
import useSelectedCampaign from 'hooks/useSelectedCampaign';
import { useSessionState } from 'hooks/useSession';
import { useSyncIsActive, useSyncOnline } from 'hooks/useSync';
import PersonIcon from 'icons/PersonIcon';
import React, { useState } from 'react';
import {
    FiCamera,
    FiChevronDown,
    FiFlag,
    FiHome,
    FiInfo,
    FiLogOut,
    FiRefreshCw,
    FiUserCheck,
    FiUsers,
} from 'react-icons/fi';
import { TbCloudCheck, TbCloudOff } from 'react-icons/tb';
import { Link } from 'react-router-dom';

import Menu, { MenuItem } from './Menu/Menu';
import CSS from './UserMenu.module.css';

export default function UserMenu() {
    const { active } = useSyncIsActive();
    const { authState } = useAuthState();
    const [isOpen, setOpen] = useState(false);
    const { session } = useSessionState();
    const syncOnline = useSyncOnline();
    const image: string | undefined =
        authState.parsedLongLived?.profile.picture;
    const isAdmin = session.parsedShortLived?.role === 'admin';
    const selectedCampaign = useSelectedCampaign();
    const openMenu = () => {
        setOpen(true);
    };
    const closeMenu = () => {
        setOpen(false);
    };

    return (
        <div className={CSS.UserMenu}>
            {selectedCampaign && (
                <Link to="/posters/sync" className={CSS.Sync}>
                    {syncOnline ? (
                        <TbCloudCheck
                            className={classNames(CSS.Cloud, {
                                [CSS.SyncActive]: active,
                            })}
                        />
                    ) : (
                        <TbCloudOff className={CSS.Cloud} />
                    )}
                </Link>
            )}
            <button className={CSS.Button} onClick={openMenu}>
                {image ? (
                    <img
                        src={image}
                        width={32}
                        height={32}
                        className={CSS.Image}
                    />
                ) : (
                    <span className={CSS.Image}>
                        <PersonIcon
                            width={24}
                            height={24}
                            className={CSS.PersonIcon}
                        />
                    </span>
                )}
                <div>
                    <span className={CSS.Name}>
                        {authState.parsedLongLived?.profile.name}
                    </span>
                    <span className={CSS.Email}>
                        {authState.parsedLongLived?.profile.email}
                    </span>
                </div>
                <FiChevronDown className={CSS.Chevron} />
            </button>

            <Menu open={isOpen} onClose={closeMenu}>
                <div className={CSS.MenuHeader}>
                    <img
                        src={image}
                        width={64}
                        height={64}
                        className={CSS.ImageLarge}
                    />
                </div>

                <MenuItem href="/" closeOnClick={closeMenu} Icon={FiHome}>
                    Home
                </MenuItem>
                {selectedCampaign && (
                    <>
                        <MenuItem
                            href="/scan-permission-request"
                            closeOnClick={closeMenu}
                            Icon={FiCamera}
                        >
                            Scan permission request
                        </MenuItem>

                        <MenuItem
                            href="/join-campaign"
                            closeOnClick={closeMenu}
                            Icon={FiUsers}
                        >
                            Change campaign
                        </MenuItem>
                        <MenuItem
                            href="/posters/sync"
                            closeOnClick={closeMenu}
                            Icon={FiRefreshCw}
                        >
                            Sync debug
                        </MenuItem>
                    </>
                )}

                <MenuItem
                    href="/feature-flags"
                    closeOnClick={closeMenu}
                    Icon={FiFlag}
                >
                    Feature flags
                </MenuItem>
                <MenuItem href="/about" closeOnClick={closeMenu} Icon={FiInfo}>
                    About this app
                </MenuItem>
                {isAdmin ? (
                    <MenuItem
                        href="/admin"
                        closeOnClick={closeMenu}
                        Icon={FiUserCheck}
                    >
                        Admin section
                    </MenuItem>
                ) : null}
                <MenuItem href="/logout" closeOnClick={closeMenu} Icon={FiLogOut}>
                    Logout
                </MenuItem>
            </Menu>
        </div>
    );
}
