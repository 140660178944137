import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { messages } from 'vite-i18n-resources';
// import LanguageDetector from 'i18next-browser-languagedetector';

console.log('messages', messages);
const i18next = i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    // .use(LanguageDetector)

    .init({
        fallbackLng: 'en-US',
        debug: import.meta.env.DEV,
        resources: messages,

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        react: {
            useSuspense: true,
        },
    });

// Only if you want hot module replacement when translation message file change
if (import.meta.hot) {
    import.meta.hot.on('locales-update', async (data) => {
        Object.keys(data).forEach((lang) => {
            Object.keys(data[lang]).forEach((ns) => {
                i18n.addResourceBundle(lang, ns, data[lang][ns], true, true);
                console.log(
                    `i18n.addResourceBundle(${lang}, ${ns}, ${data[lang][ns]}, true, true)`,
                );
            });
        });
        await i18n.reloadResources();
    });
}

console.log('languages:', i18n.languages);
// i18n.changeLanguage("nl").then(l => console.log("changed lang", l ))
// if (import.meta.env.DEV){
//   (window as any).i18n = i18n;
// }
export default i18next;
