import { GRAPHQL_ENDPOINT } from 'consts';
import { gql, GraphQLClient } from 'graphql-request';
import { jwtDecode } from 'jwt-decode';
import { useCallback, useReducer } from 'react';
import { useAsync } from 'react-use';

import { useAuthState } from './useAuth';

export async function setLongLivedToken(jwt: string) {
    localStorage.setItem('long-lived-token', jwt);
}

export async function setShortLivedToken(jwt: string) {
    localStorage.setItem('short-lived-token', jwt);
}

export async function removeShortLivedToken(): Promise<void> {
    localStorage.removeItem('short-lived-token');
}

const loginQuery = gql`
    mutation Login {
        loginFn(input: {}) {
            jwtToken
        }
    }
`;
// Note: dont be tempted to use the useGraphql hook here, its a circular dependency
export async function loginForShortLivedToken(longLivedToken: string) {
    if (longLivedToken) {
        const client = longLivedToken
            ? new GraphQLClient(GRAPHQL_ENDPOINT, {
                  headers: {
                      Authorization: `Bearer ${longLivedToken}`,
                  },
              })
            : new GraphQLClient(GRAPHQL_ENDPOINT);
        const data: any = await client.request(loginQuery);
        console.debug('loginForShortLivedToken', data.loginFn?.jwtToken);
        return data.loginFn?.jwtToken as string;
    }
    return null;
}

export interface ShortLivedTokenPayload {
    role: string;
    tokenType: 'short-lived';
    session_id: string;
    iat: number;
    exp: number;
    aud: string;
    sub: string;
}

export type SessionState =
    | {
          type: 'InSession';
          shortLivedToken: string;
          parsedShortLived: ShortLivedTokenPayload;
      }
    | { type: 'SessionLess'; parsedShortLived?: null; shortLivedToken?: null };

type AuthEvent =
    | { type: 'UpdatedShortLivedToken'; token: string }
    | { type: 'Logout' };

function tryJwtDecode(
    input: string | null | undefined,
): ShortLivedTokenPayload | null {
    if (input) {
        try {
            return jwtDecode(input);
        } catch (e) {
            console.warn('JWT invalid', input, e);
            return null;
        }
    }
    return null;
}

function initialState(): SessionState {
    const shortLivedToken = localStorage.getItem('short-lived-token');
    const parsedShortLived = tryJwtDecode(shortLivedToken);
    const isValidShortLivedToken =
        parsedShortLived?.exp && Date.now() < parsedShortLived.exp * 1000;
    if (shortLivedToken && parsedShortLived && isValidShortLivedToken) {
        return { shortLivedToken, type: 'InSession', parsedShortLived };
    }
    return { type: 'SessionLess' };
}

const reducer = (state: SessionState, action: AuthEvent): SessionState => {
    console.log('SessionReducer', state, action);
    switch (action.type) {
        case 'Logout':
            return { type: 'SessionLess' };

        case 'UpdatedShortLivedToken':
            const parsedShortLived: ShortLivedTokenPayload = jwtDecode(
                action.token,
            );
            const isValidShortLivedToken =
                parsedShortLived?.exp &&
                Date.now() < parsedShortLived.exp * 1000;
            if (parsedShortLived && isValidShortLivedToken) {
                setShortLivedToken(action.token);
                return {
                    parsedShortLived,
                    type: 'InSession',
                    shortLivedToken: action.token,
                };
            } else {
                console.warn(
                    `received short lived token not valid`,
                    parsedShortLived,
                );
                return state;
            }
        default:
            return state;
    }
};

export function useSessionState() {
    const { authState } = useAuthState();
    const [state, dispatch] = useReducer(reducer, initialState());

    const getSessionToken = useCallback(async () => {
        console.log('getSessionToken');
        if (state.type == 'InSession') {
            return {
                token: state.shortLivedToken,
                parsedShortLived: state.parsedShortLived,
            };
        }
        if (authState.longLivedToken) {
            const token = await loginForShortLivedToken(
                authState.longLivedToken,
            );
            if (!token) {
                throw new Error('unable to get session token');
            }

            const parsedShortLived: ShortLivedTokenPayload = jwtDecode(token);
            const isValidShortLivedToken =
                parsedShortLived?.exp &&
                Date.now() < parsedShortLived.exp * 1000;
            if (isValidShortLivedToken) {
                dispatch({ type: 'UpdatedShortLivedToken', token });
                return { token, parsedShortLived };
            }
            throw new Error('session token is invalid');
        }
    }, [
        authState.longLivedToken,
        state.parsedShortLived,
        state.shortLivedToken,
        state.type,
    ]);

    return {
        session: state,
        getSessionToken,
    };
}

export function useFreshSessionToken() {
    const { getSessionToken } = useSessionState();
    return useAsync(getSessionToken);
}
