import React, { ReactNode } from 'react';

import CSS from './Box.module.css';

interface BoxProps {
    children: ReactNode;
    className?: string;
}

export default function Box({ children, className }: BoxProps) {
    return <div className={`${CSS.Box} ${className}`}>{children}</div>;
}
