import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import CSS from './BackButton.module.css';

export default function BackButton() {
    const navigate = useNavigate();
    return (
        <button onClick={() => navigate(-1)} className={CSS.BackButton}>
            <FiArrowLeft className={CSS.ArrowBack} />
        </button>
    );
}
