import { GRAPHQL_ENDPOINT } from 'consts';
import { GraphQLClient } from 'graphql-request';
import { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { getSdk, SdkFunctionWrapper } from 'types/api';

import { useSessionState } from './useSession';
export type { Variables } from 'graphql-request';
import { useInterval } from 'react-use';
import { AsyncState } from 'react-use/lib/useAsync';

export function useSdk(withSession = true) {
    const { getSessionToken } = useSessionState();
    const wrapper: SdkFunctionWrapper = useCallback(
        async (action) => {
            let extraHeaders = {};
            if (withSession) {
                const session = await getSessionToken();
                extraHeaders = {
                    Authorization: `Bearer ${session?.token}`,
                };
            }
            return await action(extraHeaders);
        },
        [getSessionToken, withSession],
    );
    return useMemo(
        () =>
            getSdk(
                new GraphQLClient(GRAPHQL_ENDPOINT, {
                    // headers:{
                    //   'X-VERSION': VERSION
                    // }
                }),
                wrapper,
            ),
        [wrapper],
    );
}

type QueryState = 'init' | 'retrieving' | 'fromCache' | 'fresh';
const DBNAME = 'offline_queries_V1';
function getFromStorage<T>(name: string) {
    const fromStorage = localStorage.getItem(`${DBNAME}_${name}`);
    // console.log("getFromStorage", name)
    return fromStorage ? (JSON.parse(fromStorage) as T) : null;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function store(name: string, val: any) {
    // console.log("store", name, val)
    localStorage.setItem(`${DBNAME}_${name}`, JSON.stringify(val));
}

const CACHE_TIME = 1000 * 60 * 30;
export function useOfflineFirst<T>(
    name: string,
    getNewValue: () => Promise<T>,
) {
    const [state, setState] = useState<QueryState>('init');
    const [value, setValue] = useState<AsyncState<T>>({ loading: true });
    const refresh = useCallback(
        () =>
            getNewValue().then((newValue) => {
                setState('fresh');
                setValue({ loading: false, value: newValue });
                store(name, newValue);
                store(`${name}_cachetime`, new Date().toISOString());
            }),
        [getNewValue, name],
    );

    useEffect(() => {
        switch (state) {
            case 'init':
                const fromStorage = getFromStorage<T>(name);
                const cacheTime = getFromStorage<string>(`${name}_cachetime`);
                if (fromStorage) {
                    setValue({ loading: false, value: fromStorage });
                    setState('fromCache');
                    const cacheAgeInMs = cacheTime
                        ? Date.now() - Date.parse(cacheTime)
                        : CACHE_TIME + 1;
                    //30 minutes
                    if (cacheAgeInMs > CACHE_TIME) {
                        refresh();
                    }
                } else {
                    setState('retrieving');
                    refresh();
                }
        }
    }, [getNewValue, name, refresh, state]);
    // console.log("useOfflineFirst", name, state)

    useInterval(() => {
        refresh();
    }, CACHE_TIME);

    // console.log("useQueryOffline", name, state, value)

    return useMemo(() => ({ ...value, refresh }), [refresh, value]);
}

export function useOnlineQuery<T>(getNewValue: () => Promise<T>) {
    const [value, setValue] = useState<AsyncState<T>>({ loading: true });

    useEffect(() => {
        async function getQuery() {
            setValue({ loading: true });
            const newValue = await getNewValue();
            setValue({ loading: false, value: newValue });
        }
        getQuery();
    }, [getNewValue]);

    return value;
}
