import { createContext, useContext } from 'react';
import { RxDatabase } from 'rxdb/dist/types/types';

import { makeDb } from './deps/db';
import { PosterCollection, posterDatabase } from './models/posters';

type Collections = {
    posters: PosterCollection;
};
export type CampaignerDatabase = RxDatabase<Collections>;

// needed probably(!) only for dev-mode but cannot hurt either way
interface DbSingleton {
    db: CampaignerDatabase | null;
    campaignId: string | null;
}
declare global {
    interface Window {
        dbSingleton?: DbSingleton;
    }
}

export type CampaignerDatabaseProps = CampaignerDatabase;
export const createDb = async (
    currentUserId: string,
    campaignId: string | undefined,
    useLoki?: boolean,
): Promise<false | CampaignerDatabaseProps> => {
    if (!campaignId) return false;
    try {
        const baseName = 'campaigner_db_v8';
        const name = campaignId
            ? baseName + '_' + campaignId.replaceAll('-', '_')
            : baseName;
        let db: CampaignerDatabase | null = null;
        console.log(
            `creating database ${name} using ${useLoki ? 'loki' : 'pouch'}`,
        );

        if (
            window.dbSingleton?.db &&
            window.dbSingleton.campaignId === campaignId
        ) {
            console.log('db already exists, updating');
            db = window.dbSingleton.db;
        } else {
            if (
                window.dbSingleton?.db &&
                window.dbSingleton?.campaignId !== campaignId
            ) {
                console.log('closing existing db', window.dbSingleton.db.name);
                await window.dbSingleton.db.destroy();
            }

            try {
                console.log('created db start', name);
                db = await makeDb<Collections>(name, useLoki);
                console.log('created db done', name, db);
            } catch (e) {
                console.error('error creating db', e);
                if (db && !db.destroyed) {
                    console.warn('got error we can ignore ', e);
                } else {
                    throw e;
                }
            }
            if (!window.dbSingleton) window.dbSingleton = { db, campaignId };
            window.dbSingleton.db = db;
            window.dbSingleton.campaignId = campaignId;
        }

        // TODO: not all future campaigns will have posters
        console.log('do we have posters?', window.dbSingleton?.db?.posters);
        let collections;
        if (!window.dbSingleton?.db?.posters) {
            const posterInstance = posterDatabase(currentUserId);
            console.log('creating posters', posterInstance);
            collections = await window.dbSingleton?.db?.addCollections({
                posters: posterInstance,
            });
        }
        console.log('created posters', collections);
        console.log('returning db');
        return db;
    } catch (e) {
        console.error('error createing db', e);
        throw e;
    }
};

export const RxdbContext = createContext<CampaignerDatabaseProps>(
    null as never,
);

export function useDb() {
    return useContext(RxdbContext);
}
