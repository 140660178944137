import classnames from 'classnames';
import React, { ReactNode } from 'react';
import { ClassnamesArgument } from 'types/base';

import CSS from './Button.module.css';

interface ButtonProps {
    children: ReactNode;
    className?: ClassnamesArgument;
    onClick: () => void;
    colorVariant?: 'purple' | 'yellow' | 'red' | 'blue' | 'green' | 'white';
    noShadow?: boolean;
    disabled?: boolean;
}

function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export default function Button({
    children,
    className,
    colorVariant = 'white',
    onClick,
    noShadow,
    disabled,
}: ButtonProps) {
    return (
        <button
            disabled={disabled}
            className={classnames(
                CSS.Button,
                CSS[capitalize(colorVariant)],
                { [CSS.NoShadow]: noShadow },
                { [CSS.Disabled]: disabled },
                className,
            )}
            onClick={onClick}
        >
            {children}
        </button>
    );
}
