import { useOnlineQuery, useSdk } from 'hooks/useSdk';
import uniqueBy from 'lodash.uniqby';
import { createContext, useContext, useMemo } from 'react';
import { createGlobalState } from 'react-use';
import { ElectionsQuery, TeamFieldsFragment } from 'types/api';

export function useCampaignData() {
    const sdk = useSdk(false);
    return useOnlineQuery<ElectionsQuery>(sdk.elections);
}

export interface JoinedCampaign {
    electionId: string;
    teams?: string[];
    campaignId: string;
}

function load() {
    try {
        const unparsed = localStorage.getItem('subscribed-campaigns');
        return unparsed ? (JSON.parse(unparsed) as JoinedCampaign[]) : [];
    } catch (e) {
        return [];
    }
}

const globalCampaignState = createGlobalState<JoinedCampaign[]>(load());
// TODO, change to react-query mutation
export function useSelectCampaigns() {
    const [value, setValue] = globalCampaignState();
    const store = (v: JoinedCampaign[]) => {
        setValue(v);
        try {
            localStorage.setItem('subscribed-campaigns', JSON.stringify(v));
        } catch (e) {}
    };
    function addCampaign(
        electionId: string,
        campaignId: string,
        teams: string[],
    ) {
        const existing = value?.find((c) => c.campaignId == campaignId);
        if (existing) {
            existing.teams = teams;
            store(value);
        }
        if (!value) return setValue([{ electionId, campaignId, teams }]);
        store(
            uniqueBy(
                [{ electionId, campaignId, teams }, ...value],
                'campaignId',
            ),
        );
    }
    function removeCampaign(id: string) {
        if (!value || value.length == 0) return;
        store(value.filter((c) => c.campaignId !== id));
    }

    return { campaigns: value || [], addCampaign, removeCampaign };
}

interface JoinedCampaignsDataItem {
    election: { id: string; name: string; electionDay: string };
    campaign: { id: string; name: string; createdAt: string };
    teams: TeamFieldsFragment[];
}
export interface JoinedCampaignsData {
    isLoading: boolean;
    all: Array<JoinedCampaignsDataItem> | null;
    current?: JoinedCampaignsDataItem | null;
}

//TODO: change to react query query
export function useJoinedCampaignInfo(): JoinedCampaignsData {
    const [value] = globalCampaignState();
    const elections = useCampaignData();

    return useMemo(() => {
        if (elections.loading) return { isLoading: true, all: null };

        if (!elections.value?.electionsList) {
            return { isLoading: false, all: null };
        }
        const all = ((value || [])
            .map(({ electionId, campaignId, teams }) => {
                const election = elections.value.electionsList?.find(
                    (e) => e.id == electionId,
                );
                const campaign = election?.campaignsList.find(
                    (c) => c.id == campaignId,
                );
                const teamsJoined =
                    campaign?.teamsList?.filter(({ id }) =>
                        teams?.includes(id),
                    ) || [];

                if (election && campaign) {
                    return {
                        election: {
                            id: election.id,
                            name: election.name,
                            electionDay: election.electionDay,
                        },
                        campaign: {
                            id: campaign.id,
                            createdAt: campaign.createdAt,
                            name: campaign.name,
                        },
                        teams: teamsJoined || [],
                    };
                }
                return null;
            })
            .filter((x) => !!x) || []) as Array<JoinedCampaignsDataItem>;
        return { all, current: all ? all[0] : null, isLoading: false };
    }, [elections.loading, elections.value, value]);
}

export const CampaignContext = createContext<JoinedCampaignsDataItem>(
    null as never,
);
export function useCurrentCampaign() {
    return useContext(CampaignContext);
}
