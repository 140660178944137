import classNames from 'classnames';
import React, { ReactNode, useRef } from 'react';
import { IconType } from 'react-icons';
import { Link } from 'react-router-dom';

import CSS from './Menu.module.css';

export interface MenuItemProps {
    children: ReactNode;
    onClick?: () => void;
    href?: string;
    closeOnClick?: () => void;
    Icon: IconType; // React-icons icons
}

export function MenuItem({
    children,
    href,
    onClick,
    closeOnClick,
    Icon,
}: MenuItemProps) {
    if (href) {
        return (
            <Link className={CSS.MenuItem} to={href} onClick={closeOnClick}>
                <div className={CSS.MenuItemInside}>
                    {Icon && <Icon />}
                    {children}
                </div>
            </Link>
        );
    } else {
        return (
            <div
                className={CSS.MenuItem}
                onClick={() => {
                    onClick && onClick();
                    closeOnClick && closeOnClick();
                }}
            >
                <div className={CSS.MenuItemInside}>{children}</div>
            </div>
        );
    }
}

export interface MenuProps {
    open: boolean;
    onClose?: () => void;
    children: ReactNode;
}
export default function Menu({ open, onClose, children }: MenuProps) {
    const menuRef = useRef<HTMLDivElement>(null);
    const close: React.MouseEventHandler<HTMLDivElement> = (e) => {
        onClose && onClose();
        e.preventDefault();
    };
    return (
        <>
            <div
                onClick={close}
                className={classNames(CSS.MenuBackdrop, {
                    [CSS.MenuBackdropOpen]: open,
                })}
            />
            <div
                ref={menuRef}
                className={classNames(CSS.Menu, { [CSS.MenuOpen]: open })}
            >
                <div className={CSS.MenuLocationIndicator} />
                {children}
            </div>
        </>
    );
}
