import classnames from 'classnames';
import React from 'react';

import CSS from './LoadingDots.module.css';

export default function LoadingDots() {
    return (
        <div className={CSS.Container}>
            <div className={classnames(CSS.DotBlock, CSS.DotBlockVar1)} />
            <div className={classnames(CSS.DotBlock, CSS.DotBlockVar2)} />
            <div className={classnames(CSS.DotBlock, CSS.DotBlockVar3)} />
            <div className={classnames(CSS.DotBlock, CSS.DotBlockVar4)} />
        </div>
    );
}
