import VoltLogo from 'assets/volt-logo-small.png';
import BackButton from 'components/BackButton/BackButton';
import UserMenu from 'components/UserMenu/UserMenu';
import React from 'react';
import { Link } from 'react-router-dom';

import CSS from './TopMenu.module.css';

interface TopMenuProp {
    showBack?: boolean;
}

export default function TopMenu({ showBack }: TopMenuProp) {
    return (
        <div className={CSS.TopMenu}>
            {showBack ? (
                <BackButton />
            ) : (
                <Link to="/" className={CSS.Volt}>
                    <img src={VoltLogo} alt="Volt" />
                </Link>
            )}
            <UserMenu />
        </div>
    );
}
