import { GeoJSON } from 'geojson';
import { RxCollection, RxDocumentData, RxJsonSchema } from 'rxdb';
import { KeyFunctionMap, RxCollectionCreator, RxDocument } from 'rxdb';
import { Sdk } from 'types/api';
import { PouchDoc } from 'types/base';
import * as uuid from 'uuid';

export interface PosterLocation {
    type: 'Point';
    coordinates: [number, number]; //lon,lat
}

export interface PosterDocType extends PouchDoc {
    id: string; //uuid
    docType: 'poster';
    location: PosterLocation;
    campaignId: string;
    postertypeId: string | null;
    description: string | null;
    imageUrl: string | null;
    removed: boolean;
    entryDeleted?: boolean;
    createdAt: string;
    updatedAt: string;
    createdBy?: string;
}

export interface PosterFields {
    location: PosterLocation;
    campaignId: string;
    postertypeId: string | null;
    description: string | null;
    imageUrl: string | null;
}

// can be added to the Poster docs coming from the database
export interface PosterDocMethods {
    markRemoved: () => Promise<void>;
    geojson: (
        props?: GeoJSON.GeoJsonProperties,
    ) => GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
}
export type RxPosterDoc = RxDocument<PosterDocType, PosterDocMethods>;

const posterDocMethods: PosterDocMethods & KeyFunctionMap = {
    markRemoved: async function (this: RxPosterDoc): Promise<void> {
        const result = await this.atomicPatch({
            removed: true,
            updatedAt: new Date().toISOString(),
        });
        console.log('removed', result.removed);
    },
    geojson: function (
        this: RxPosterDoc,
        props?: GeoJSON.GeoJsonProperties,
    ): GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> {
        return {
            type: 'Feature',
            properties: {
                ...(props || {}),
                posterId: this.id,
            },
            id: this.id,
            geometry: this.location,
        };
    },
};

export interface PosterCollectionMethods extends KeyFunctionMap {
    markPoster: (
        poster: PosterFields,
    ) => Promise<RxDocument<PosterDocType, PosterDocMethods>>;
}

export type PosterCollection = RxCollection<
    PosterDocType,
    PosterDocMethods,
    PosterCollectionMethods
>;

export const posterCollectionMethods: PosterCollectionMethods = {
    markPoster: async function (this: PosterCollection, poster: PosterFields) {
        const now = new Date().toISOString();
        const doc: PosterDocType = {
            ...poster,
            id: uuid.v4(),
            removed: false,
            entryDeleted: false,
            docType: 'poster',
            createdAt: now,
            updatedAt: now,
        };
        console.log('markPoster', doc);
        try {
            const result = await this.insert(doc);
            console.log('created poster', result);
            return result;
        } catch (err) {
            console.error('error inserting new poster', doc, err);
            throw err;
        }
    },
};

export const posterJsonSchema: RxJsonSchema<PosterDocType> = {
    title: 'poster schema',
    description: 'describes a poster',
    version: 7,
    keyCompression: true,
    primaryKey: 'id',
    type: 'object',
    properties: {
        id: { type: 'string' },
        docType: { type: 'string' },
        location: {
            type: 'object',
            properties: {
                type: { type: 'string' },
                coordinates: { type: 'array', items: { type: 'number' } },
            },
        },
        campaignId: { type: ['string', 'null'] },
        postertypeId: { type: ['string', 'null'] },
        description: { type: ['string', 'null'] },
        imageUrl: { type: ['string', 'null'] },
        removed: { type: 'boolean' },
        entryDeleted: { type: 'boolean' },
        updatedAt: { type: 'string' },
        createdAt: { type: 'string' },
        createdBy: { type: 'string' },
    },
    required: ['id', 'docType', 'location', 'updatedAt', 'createdAt'],
    indexes: ['removed', 'updatedAt', 'campaignId'],
};

export const posterDatabase: (userId: string) => RxCollectionCreator = (
    currentUserId: string,
) => ({
    schema: posterJsonSchema,
    methods: posterDocMethods,
    statics: posterCollectionMethods,
    migrationStrategies: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        1: ({ photo, ...oldDoc }: any) => {
            return { ...oldDoc, imageUrl: photo };
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        2: (doc: any) => ({ ...doc, entryDeleted: false }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        3: ({ posterType, ...doc }: any) => ({
            ...doc,
            posterTypeId: posterType,
        }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        4: ({ posterTypeId, ...doc }: any) => ({
            ...doc,
            postertypeId: posterTypeId,
        }),
        // 2: (oldDoc:any) =>{
        //   return {...oldDoc,location:{ type:"Point", coordinates:[oldDoc.location.longitude, oldDoc.location.latitude] } }
        // }
        // [toVersion]: (oldDoc:oldDoc: PosterDocType) => PosterDocType|null
        5: (doc) => {
            console.log('updating doc', doc);
            return doc;
        },
        6: (doc) => {
            console.log('updating doc', doc);
            return doc;
        },
        7: (doc: PosterDocType) => {
            if (doc.createdBy !== currentUserId) {
                return null;
            }
            return doc;
        },
    },
});

const longTimeAgo = new Date();
longTimeAgo.setFullYear(1900);
export const pullQueryBuilder =
    (campaignId: string, userId?: string) =>
    (doc?: RxDocumentData<PosterDocType> | null) => {
        const query = `query SyncQuery($updatedAt:Datetime!, $campaignId:UUID!${
            userId ? ',$userId:String' : ''
        }) {
    postersList(
      filter: {
        and: {
          updatedAt: {greaterThan: $updatedAt},
          campaignId : {equalTo: $campaignId},
          ${userId ? 'createdBy: {equalTo: $userId}' : ''}
        }
      }
      orderBy: UPDATED_AT_ASC
      first: 50
    ){
        id
        location{
          geojson
        }
        description
        campaignId
        postertypeId
        removed
        updatedAt
        createdAt
        createdBy
    }
  }`;

        return {
            query,
            variables: {
                updatedAt: doc?.updatedAt || longTimeAgo.toISOString(),
                campaignId: campaignId,
                userId,
            },
        };
    };

const createPoster = `mutation createPoster($id: UUID!, $sessionId:UUID!, $userId:String!, $location: GeoJSON!, $campaignId:UUID, $postertypeId:UUID, $description:String, $imageUrl:String, $removed: Boolean!, $updatedAt:Datetime!, $createdAt:Datetime!) {
  createPoster(input: {
    poster:{
      id: $id,
      location: $location,
      updatedAt: $updatedAt,
      createdAt: $createdAt,
      campaignId: $campaignId,
      postertypeId: $postertypeId,
      description: $description,
      imageUrl: $imageUrl,
      removed: $removed,
      createdWith: $sessionId,
      createdBy: $userId
    }
  }){
    poster{
      id
    }
  }
}`;

const updatePoster = `mutation updatePoster($id: UUID!, $sessionId:UUID!, $userId:String!, $location: GeoJSON!, $campaignId:UUID, $postertypeId:UUID, $description:String, $imageUrl:String, $removed: Boolean!, $updatedAt:Datetime!, $createdAt:Datetime!) {
  updatePoster(input: {
    patch:{
      location: $location,
      updatedAt: $updatedAt,
      createdAt: $createdAt,
      campaignId: $campaignId,
      postertypeId: $postertypeId,
      removed: $removed,
      description: $description,
      imageUrl: $imageUrl,
      createdWith: $sessionId,
      createdBy: $userId
    }, id: $id,
  }){
    poster{
      id
    }
  }
}`;

export const pushQueryBuilder =
    (sdk: Sdk, userId?: string, sessionId?: string) =>
    async (docs: RxDocumentData<PosterDocType>[]) => {
        const doc = Array.isArray(docs) ? docs[0] : docs;
        console.log('pushing doc', doc);
        let posterExists = false;
        try {
            const remoteDetails = await sdk.posterDetails({ id: doc.id });
            posterExists = !!remoteDetails.poster;
        } catch (e) {
            posterExists = false;
        }
        const shouldCreate = !posterExists;
        console.log('pushing doc args', shouldCreate ? 'created' : 'updated');
        const query = shouldCreate ? createPoster : updatePoster;

        const postertypeId = doc.postertypeId?.length == 36 ? doc.postertypeId : null;

        const variables = {
            id: doc.id,
            updatedAt: doc.updatedAt,
            createdAt: doc.createdAt,
            removed: doc.removed,
            description: doc.description,
            imageUrl: doc.imageUrl,
            location: {
                ...doc.location,
                crs: {
                    type: 'name',
                    properties: {
                        name: 'urn:ogc:def:crs:EPSG::4326',
                    },
                },
            },
            postertypeId: postertypeId,
            campaignId: doc.campaignId,
            userId,
            sessionId,
        };
        return {
            query,
            variables,
        };
    };
