/* eslint-disable @typescript-eslint/no-explicit-any */

import * as Sentry from '@sentry/react';
import { FallbackRender } from '@sentry/react/types/errorboundary';
import Box from 'components/Box/Box';
import Button from 'components/Button/Button';
import { H1, P } from 'components/Typography/Typography';
import { clearAllStorage } from 'hooks/useAuth';
import React, { ReactNode } from 'react';
import { useAsyncFn } from 'react-use';

import CSS from './ErrorBoundary.module.css';

export const ErrorPage = ({
    error,
    body,
    db,
}: {
    error: any;
    body: any;
    db?: boolean;
}) => {
    const [state, clear] = useAsyncFn(clearAllStorage, []);
    console.error('ErrorPage', error, body, db);
    return (
        <Box className={CSS.Box}>
            <H1>Oops; Something went wrong; {error.toString()}</H1>
            <P>
                This error automatically send to a monitoring service if you
                currently have internet.
            </P>
            <div>
                <code>
                    Error:
                    <br />
                    {body}
                </code>
            </div>
            {db ? (
                <div>
                    <>
                        <Button
                            colorVariant="red"
                            onClick={clear}
                            disabled={state.loading}
                        >
                            Reset the app
                        </Button>
                        {state.value && 'Done! Now Reloading page'}
                    </>
                </div>
            ) : null}
        </Box>
    );
};

const FallBack: FallbackRender = ({ error, componentStack }) => (
    <ErrorPage error={error} body={componentStack} />
);

interface ErrorProps {
    children: ReactNode;
    name: string;
}
export default function ErrorBoundary({ children, name }: ErrorProps) {
    return (
        <Sentry.ErrorBoundary
            fallback={FallBack}
            showDialog={process.env.NODE_ENV === 'production'}
            beforeCapture={(scope) => {
                scope.setTag('boundary', name);
            }}
        >
            {children}
        </Sentry.ErrorBoundary>
    );
}
