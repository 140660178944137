import cx from 'classnames';
import React, { ReactNode } from 'react';

import CSS from './Typography.module.css';

export interface TypoGraphyProps {
    children: ReactNode;
    className?: string;
    color?: 'Purple' | 'Yellow' | 'Red' | 'Blue' | 'Green' | 'White';
}
export function H1({ children, className, color = 'White' }: TypoGraphyProps) {
    return <h1 className={cx(CSS.H1, CSS[color], className)}>{children}</h1>;
}

export function H2({ children, className, color = 'Purple' }: TypoGraphyProps) {
    return <h2 className={cx(CSS.H2, CSS[color], className)}>{children}</h2>;
}

export function P({ children, className, color = 'Purple' }: TypoGraphyProps) {
    return <p className={cx(CSS.P, CSS[color], className)}>{children}</p>;
}
