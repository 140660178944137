import * as Sentry from '@sentry/react';
import { urlEncode } from '@sentry/utils';
import { AUTH_ENDPOINT } from 'consts';
import { jwtDecode } from 'jwt-decode';
import { useCallback, useEffect, useMemo } from 'react';
import { createReducerContext } from 'react-use';

import { removeShortLivedToken } from './useSession';

export async function setLongLivedToken(jwt: string) {
    localStorage.setItem('long-lived-token', jwt);
}

export async function removeLongLivedToken(): Promise<void> {
    localStorage.removeItem('long-lived-token');
}

export function loginUrl() {
    return `${AUTH_ENDPOINT}/auth/create?${urlEncode({ return: location.href })}`;
}

export interface LongLivedTokenPayload {
    role: string;
    tokenType: 'long-lived';
    userId: string;
    profile: {
        id: string;
        email: string;
        verified_email: boolean;
        name: string;
        given_name: string;
        family_name: string;
        picture: string;
        locale: string;
    };
    iat: number;
    exp: number;
    aud: string;
    sub: string;
}

interface LoggedInState {
    longLivedToken: string;
    parsedLongLived: LongLivedTokenPayload;
    role: 'member' | 'guest';
    type: 'LoggedIn';
}
interface LoggedOutState {
    type: 'LoggedOut';
    role: 'unauth';
    longLivedToken?: null;
    parsedLongLived?: null;
}

export type AuthState = LoggedInState | LoggedOutState;

type AuthEvent = { type: 'UrlTokenFound'; token: string } | { type: 'Logout' };

function tryJwtDecode(
    input: string | null | undefined,
): LongLivedTokenPayload | null {
    if (input) {
        try {
            return jwtDecode(input);
        } catch (e) {
            console.warn('JWT invalid', input, e);
            return null;
        }
    }
    return null;
}

function isExpired(parsedLongLived: LongLivedTokenPayload): boolean {
    const isValidLongLivedToken =
        parsedLongLived?.exp && Date.now() < parsedLongLived.exp * 1000;

    return !isValidLongLivedToken;
}

function initialState(): AuthState {
    const longLivedToken = localStorage.getItem('long-lived-token');
    const parsedLongLived = tryJwtDecode(longLivedToken);
    if (parsedLongLived && longLivedToken && !isExpired(parsedLongLived)) {
        const isVoltMember =
            parsedLongLived.profile.email.endsWith('@volteuropa.org');
        return {
            longLivedToken,
            parsedLongLived,
            type: 'LoggedIn',
            role: isVoltMember ? 'member' : 'guest',
        };
    }
    return { type: 'LoggedOut', role: 'unauth' };
}

const LoggedInReducer = (
    state: LoggedInState,
    action: AuthEvent,
): AuthState => {
    console.log('AuthReducer', state, action);
    switch (action.type) {
        case 'Logout':
            return { type: 'LoggedOut', role: 'unauth' };
        default:
            return state;
    }
};
const LoggedOutReducer = (
    state: LoggedOutState,
    action: AuthEvent,
): AuthState => {
    console.log('AuthReducer', state, action);
    switch (action.type) {
        case 'UrlTokenFound':
            const parsedLongLived = tryJwtDecode(action.token);
            if (parsedLongLived && parsedLongLived.tokenType === 'long-lived') {
                setLongLivedToken(action.token);
                const isVoltMember =
                    parsedLongLived.profile.email.endsWith('@volteuropa.org');
                return {
                    type: 'LoggedIn',
                    role: isVoltMember ? 'member' : 'guest',
                    longLivedToken: action.token,
                    parsedLongLived,
                };
            } else {
                console.warn(
                    `received long lived token not valid`,
                    parsedLongLived,
                );
            }

        default:
            return state;
    }
};

const reducer = (state: AuthState, action: AuthEvent): AuthState => {
    switch (state.type) {
        case 'LoggedIn':
            return LoggedInReducer(state, action);
        case 'LoggedOut':
            return LoggedOutReducer(state, action);
        default:
            return state;
    }
};

const [useAuthReducer, Provider] = createReducerContext(
    reducer,
    initialState(),
);
export const AuthStateProvider = Provider;

export const clearAllStorage = async () => {
    localStorage.clear();
    const idb = window.indexedDB;
    if (idb.databases) {
        await idb
            .databases()
            .then((dbs) =>
                Promise.all(
                    dbs.map((db) => db.name && idb.deleteDatabase(db.name)),
                ),
            );
    }
    setTimeout(() => {
        window.location.href = '/';
    }, 500);
    return;
};

export function useAuthState() {
    const [state, dispatch] = useAuthReducer();
    // the Auth backend server redirects back to the app with the token param set
    const tokenFromUrl = new URLSearchParams(window.location.search).get(
        'token',
    );

    useEffect(() => {
        if (tokenFromUrl && state.type === 'LoggedOut') {
            dispatch({ type: 'UrlTokenFound', token: tokenFromUrl });
        }
    }, [tokenFromUrl, state, dispatch]);

    const logout = useCallback(
        async (removeData: boolean) => {
            if (removeData) {
                clearAllStorage();
            }
            await removeLongLivedToken();
            await removeShortLivedToken();

            dispatch({ type: 'Logout' });
            setTimeout(() => {
                window.location.href = '/';
            }, 500);
        },
        [dispatch],
    );

    useEffect(() => {
        Sentry.setUser({
            id: state.parsedLongLived?.profile.id,
            email: state.parsedLongLived?.profile.email,
        });
        Sentry.setContext('role', { role: state?.parsedLongLived?.role });
    }, [
        state.parsedLongLived?.profile.email,
        state.parsedLongLived?.profile.id,
        state.parsedLongLived?.role,
    ]);

    return {
        authState: state,
        logout,
    };
}

export function useUserId() {
    const authState = useAuthState();
    return useMemo(
        () => authState.authState.parsedLongLived?.sub,
        [authState.authState.parsedLongLived?.sub],
    );
}

export function useMyProfile() {
    const authState = useAuthState();
    return useMemo(
        () => authState.authState.parsedLongLived?.profile,
        [authState.authState.parsedLongLived?.profile],
    );
}
