import LokiIncrementalIndexedDBAdapter from 'lokijs/src/incremental-indexeddb-adapter';
import idbPlugin from 'pouchdb-adapter-idb';
import { addRxPlugin, createRxDatabase } from 'rxdb';
import { RxDBJsonDumpPlugin } from 'rxdb/plugins/json-dump';
import { getRxStorageLoki } from 'rxdb/plugins/lokijs';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { addPouchPlugin, getRxStoragePouch } from 'rxdb/plugins/pouchdb';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { RxDBReplicationGraphQLPlugin } from 'rxdb/plugins/replication-graphql';
import idbReady from 'safari-14-idb-fix';

window.process = window.process || {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
window.global = window as any;

addRxPlugin(RxDBReplicationGraphQLPlugin);
addPouchPlugin(idbPlugin);
addRxPlugin(RxDBQueryBuilderPlugin);
addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBJsonDumpPlugin);

export async function makeDb<T>(name: string, useLoki?: boolean) {
    await idbReady();
    return await createRxDatabase<T>({
        name,
        storage: useLoki
            ? getRxStorageLoki({
                  adapter: new LokiIncrementalIndexedDBAdapter(),
              })
            : (getRxStoragePouch('idb', {
                  auto_compaction: true,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
              }) as any),
    });
}
