import * as Sentry from '@sentry/react';
import LoadingDots from 'components/LoadingDots/LoadingDots';
import Page from 'components/Page/Page';
import { H1, H2 } from 'components/Typography/Typography';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useTimeout } from 'react-use';

import CSS from './LoadingPage.module.css';

export const LoadingPage = ({ takesLong }: { takesLong?: boolean }) => {
    const [showWait] = useTimeout(1000);
    const [stillWait] = useTimeout(10000);
    const [verySlow] = useTimeout(20000);
    useEffect(() => {
        if (takesLong && verySlow()) {
            Sentry.captureMessage('Very very slow loading');
        }
    }, [takesLong, verySlow]);
    return (
        <Page className={CSS.Page}>
            <H1 className={CSS.H1}>
                <Trans ns="home" i18nKey="loading-loading">
                    Loading
                </Trans>
            </H1>
            <LoadingDots />

            {takesLong && verySlow() ? (
                <H2 className={CSS.H2}>
                    <Trans ns="home" i18nKey="loading-busy">
                        Hmm... 🤔... this might be too slow.
                    </Trans>
                </H2>
            ) : null}

            {takesLong && !stillWait() && !verySlow() && showWait() ? (
                <H2 className={CSS.H2}>
                    <Trans ns="home" i18nKey="loading-busy">
                        Please be patient, the server is still waking up after
                        being asleep...
                    </Trans>
                </H2>
            ) : null}

            {takesLong && stillWait() && showWait() ? (
                <H2 className={CSS.H2}>
                    <Trans ns="home" i18nKey="loading-busy">
                        Any moment now...
                    </Trans>
                </H2>
            ) : null}
        </Page>
    );
};
